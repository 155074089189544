import axios from "@/config/axios";

export default {
  namespaced: true,
  state() {
    return {
      directories: [],
      current_directory: {},
      loading: false,
    };
  },
  getters: {
    directories(state) {
      return state.directories;
    },
    currentDirectory(state) {
      return state.current_directory;
    },
    loading(state) {
      return state.loading;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit("SET_LOADING", true);
      const { data } = await axios.get("/directories");
      commit("SET_DIRECTORIES", data.data);
      commit("SET_LOADING", false);
    },
    async findOne({ commit }, id) {
      commit("SET_LOADING", true);
      const { data } = await axios.get(`/directories/${id}`);
      commit("SET_CURRENT_DIRECTORY", data.data);
      commit("SET_LOADING", false);
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
  },
  mutations: {
    SET_DIRECTORIES(state, directories) {
      state.directories = directories;
    },
    SET_CURRENT_DIRECTORY(state, directory) {
      state.current_directory = directory;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
};
