// import HomeView from "@/views/HomeView.vue";
// import CasesCreateView from "@/views/CasesCreateView.vue";
// import LoginView from "@/views/LoginView.vue";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      title: "GICAM",
      authRequired: true,
    },
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "GICAM",
      authRequired: false,
    },
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/cases/list/create",
    name: "cases-create",
    meta: { title: "Ajouter un dossier", authRequired: true },
    component: () => import("../views/CasesCreateView.vue"),
  },
  {
    path: "/campaign/events",
    name: "campagnes-events",
    meta: { title: "Dashboard événements", authRequired: true },
    component: () => import("../views/campaign/events/EventDashboardView.vue"),
  },
  {
    path: "/campaign/events/create",
    name: "campagnes-events-create",
    meta: { title: "Ajouter un événement", authRequired: true },
    component: () => import("../views/campaign/events/EventCreateView.vue"),
  },
  {
    path: "/campaign/events/:eventId/details",
    name: "campagnes-events-details",
    meta: { title: "Détails de l'événement", authRequired: true },
    component: () => import("../views/campaign/events/EventDetailsView.vue"),
  },
  {
    path: "/campaign/events/:eventId/settings",
    name: "campagnes-events-settings",
    meta: { title: "Détails de l'événement", authRequired: true },
    component: () => import("../views/campaign/events/EventSettingsView.vue"),
  },
  {
    path: "/system/settings",
    name: "system-settings",
    meta: { title: "Configuration du système", authRequired: true },
    component: () => import("../views/SettingsView.vue"),
  },
  {
    path: "/system/admins",
    name: "system-admins",
    meta: { title: "Administrateurs du système", authRequired: true },
    component: () => import("../views/admin/AdminListView.vue"),
  },
  {
    path: "/campaign/sms",
    name: "campagnes-sms",
    meta: { title: "Campagnes SMS", authRequired: true },
    component: () =>
      import("../views/campaign/sms/CampaignSMSDashboardView.vue"),
  },
  {
    path: "/campaign/sms/create",
    name: "campagnes-sms-create",
    meta: { title: "Nouvelle campagnes SMS", authRequired: true },
    component: () => import("../views/campaign/sms/CampaignSMSCreateView.vue"),
  },
  {
    path: "/campaign/sms/:campaignId/details",
    name: "campagnes-sms-details",
    meta: { title: "Détails campagnes SMS", authRequired: true },
    component: () => import("../views/campaign/sms/CampaignSMSDetailsView.vue"),
  },
  {
    path: "/campaign/sms/recharge-logs",
    name: "campagnes-sms-recharge-logs",
    meta: { title: "Historique des recharges SMS", authRequired: true },
    component: () => import("../views/campaign/sms/SMSRechargeLogView.vue"),
  },
];
