import AdminServices from "@/services/AdminServices";

export default {
  namespaced: true,

  state() {
    return {
      admins: [],
      current_admin: {},
      loading: false,
    };
  },
  getters: {
    admins(state) {
      return state.admins;
    },
    currentAdmin(state) {
      return state.current_admin;
    },
    loading(state) {
      return state.loading;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit("SET_LOADING", true);
      const { data } = await AdminServices.fetchAll();
      commit("SET_ADMINS", data.data.admins);
      commit("SET_LOADING", false);
    },
    async findOne({ commit }, adminId) {
      commit("SET_LOADING", true);
      const { data } = await AdminServices.fetchOne(adminId);
      commit("SET_CURRENT_ADMIN", data.data.admin);
      commit("SET_LOADING", false);
    },
    async setCurrentAdmin({ commit }, admin) {
      commit("SET_CURRENT_ADMIN", admin);
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
  },
  mutations: {
    SET_ADMINS(state, admins) {
      state.admins = admins;
    },
    SET_CURRENT_ADMIN(state, admin) {
      state.current_admin = admin;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
};
