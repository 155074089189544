import Axios from "@/config/axios";

export default {
  async fetchAll() {
    return Axios.get("/admins");
  },
  async fetchOne(adminId) {
    return Axios.get(`/admins/${adminId}/show`);
  },
  async create(data) {
    return Axios.post(`/admins/create`, data);
  },
  async update(adminId, data) {
    return Axios.put(`/admins/${adminId}/update`, data);
  },
  async delete(adminId) {
    return Axios.delete(`/admins/${adminId}/delete`);
  },
};
