<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;

      const isBackward = toDepth < fromDepth;

      const transitionName = isBackward
        ? "scale-slide-right"
        : "scale-slide-left";
      const transitionMode = isBackward ? "out-in" : "in-out";

      this.$store.dispatch("setTransitionName", transitionName);
      this.$store.dispatch("setTransitionMode", transitionMode);
    },
  },
  methods: {},
};
</script>

<style>
table {
  margin-bottom: 150px !important;
}
</style>
