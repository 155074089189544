import axios from "@/config/axios";

export default {
  namespaced: true,
  state() {
    return {
      documents: [],
      current_document: {},
      loading: false,
    };
  },
  getters: {
    documents(state) {
      return state.documents;
    },
    currentDocument(state) {
      return state.current_document;
    },
    loading(state) {
      return state.loading;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit("SET_LOADING", true);
      const { data } = await axios.get("/users");
      commit("SET_DOCUMENTS", data.data.societies);
      commit("SET_LOADING", false);
    },
    async findOne({ commit }, matricule) {
      commit("SET_LOADING", true);
      const { data } = await axios.get(`/users/${matricule}`);
      commit("SET_CURRENT_DOCUMENT", data.data.user);
      commit("SET_LOADING", false);
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
  },
  mutations: {
    SET_DOCUMENTS(state, documents) {
      state.documents = documents;
    },
    SET_CURRENT_DOCUMENT(state, document) {
      state.current_document = document;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
};
