import EventServices from "@/services/EventServices";

export default {
  namespaced: true,
  state() {
    return {
      events: [],
      current_event: {},
      loading: false,
    };
  },
  getters: {
    events(state) {
      return state.events;
    },
    currentEvent(state) {
      return state.current_event;
    },
    loading(state) {
      return state.loading;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit("SET_LOADING", true);
      const { data } = await EventServices.fetchAll();
      commit("SET_EVENTS", data.data.events);
      commit("SET_LOADING", false);
    },
    async findOne({ commit }, eventId) {
      commit("SET_LOADING", true);
      const { data } = await EventServices.fetchOne(eventId);
      commit("SET_CURRENT_EVENT", data.data.event);
      commit("SET_LOADING", false);
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
  },
  mutations: {
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_CURRENT_EVENT(state, event) {
      state.current_event = event;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
};
