import Axios from "@/config/axios";

class EventServices {
  static async fetchAll() {
    return Axios.get("/events");
  }
  static async fetchOne(eventId) {
    return Axios.get(`/events/${eventId}/show`);
  }

  static async create(data) {
    return Axios.post(`/events/create`, data);
  }

  static async start(eventId) {
    return Axios.patch(`/events/${eventId}/start`);
  }
  static async finish(eventId) {
    return Axios.patch(`/events/${eventId}/finish`);
  }

  static async diffuse(eventId) {
    return Axios.patch(`/events/${eventId}/diffuse`);
  }

  static async update(eventId, data) {
    return Axios.put(`/events/${eventId}/update`, data);
  }

  static async delete(eventId) {
    return Axios.delete(`/events/${eventId}/delete`);
  }

  static async fetchMembers(eventId) {
    return Axios.get(`/events/${eventId}/members`);
  }
}

export default EventServices;
