import axios from "@/config/axios";

export default {
  namespaced: true,
  state() {
    return {
      configs: {},
      loading: false,
    };
  },
  getters: {
    configs(state) {
      return state.configs;
    },
  },
  actions: {
    async find({ commit }) {
      commit("SET_LOADING", true);
      const { data } = await axios.get(`/smtp-configs`);
      commit("SET_CONFIGS", data.data);
      commit("SET_LOADING", false);
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
  },
  mutations: {
    SET_CONFIGS(state, configs) {
      state.configs = configs;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
};
