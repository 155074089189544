import Axios from "@/config/axios";

export default {
  async fetchAll() {
    return Axios.get("/campaigns");
  },
  async fetchOne(campaignId) {
    return Axios.get(`/campaigns/${campaignId}/show`);
  },
  async create(data) {
    return Axios.post(`/campaigns/create`, data);
  },
  async diffuse(campaignId) {
    return Axios.patch(`/campaigns/${campaignId}/diffuse`);
  },
  async delete(campaignId) {
    return Axios.delete(`/campaigns/${campaignId}/delete`);
  },
  async fetchTargets(campaignId) {
    return Axios.get(`/campaigns/${campaignId}/targets`);
  },
  async resend(campaignId, targetId) {
    return Axios.post(`/campaigns/${campaignId}/targets/${targetId}/resend`);
  },
};
