import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import store from "@/state/store";
// import Axios from "axios";
import Axios from "@/config/axios";

import { BASE_URL } from "../helpers/constants";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  store.dispatch("setGlobalLoading", true);

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  // eslint-disable-next-line no-unreachable
  if (!authRequired) return next();

  // if (localStorage.getItem("userid")) {
  //   Axios.get(BASE_URL + "/auth/me", {
  //     headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
  //   })
  //     .then(({ data }) => {
  //       console.log(data);
  //       localStorage.setItem("userdata", JSON.stringify(data.data.user));
  //       localStorage.setItem("userid", data.data.user.id);
  //       localStorage.setItem("user", JSON.stringify(data.data.user));
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
  //     });
  // } else {
  await Axios.get(BASE_URL + "/auth/me", {
    headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
  })
    .then(({ data }) => {
      console.log(data);
      localStorage.setItem("userdata", JSON.stringify(data.data.user));
      localStorage.setItem("userid", data.data.user.id);
      localStorage.setItem("user", JSON.stringify(data.data.user));
    })
    .catch((e) => {
      console.log(e);
      next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
    });
  // }

  // if (store.getters["smtp/configs"] == {}) {
  //   await store.dispatch("smtp/find");
  // } else {
  //   store.dispatch("smtp/find");
  // }
  store.dispatch("system/fetchPartner");

  store.dispatch("events/findAll");
  store.dispatch("campaigns/findAll");
  store.dispatch("system/fetchSenders");

  return next();
});

router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ["/login", "/register", "/forgot-password"];
  const authpage = !publicPages.includes(routeTo.path);
  const loggeduser = localStorage.getItem("user");

  // TODO - Check if user is loggedIn
  if (authpage && !loggeduser) {
    return next("/login");
  }

  next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }
  document.title = routeTo.meta.title || "Document Manager";
  // If we reach this point, continue resolving the route.
  next();
});

// router.afterEach((routeTo, routeFrom) => {
router.afterEach(() => {
  store.dispatch("setGlobalLoading", false);
});

export default router;
