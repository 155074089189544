import axios from "axios";
import { BASE_URL } from "../helpers/constants";

// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.crossDomain = true;

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.baseURL = BASE_URL;

    const token = localStorage.getItem("jwt");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    // config.headers.set("Authorization", "Bearer " + token);

    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

export default axios;
