import SystemServices from "@/services/SystemServices";

export default {
  namespaced: true,
  state() {
    return {
      senders: [],
      partner: {},
      loading: false,
    };
  },
  getters: {
    senders(state) {
      return state.senders;
    },
    partner(state) {
      return state.partner;
    },
    isAdmin() {
      const data = localStorage.getItem("user");
      return data ? JSON.parse(data).role == "ADMIN" : false;
    },
    loggedUser() {
      const data = localStorage.getItem("user");
      return data ? JSON.parse(data) : {};
    },
    loading(state) {
      return state.loading;
    },
  },
  actions: {
    async fetchPartner({ commit }) {
      commit("SET_LOADING", true);
      const { data } = await SystemServices.fetchPartner();
      commit("SET_PARTNER", data.partner);
      commit("SET_LOADING", false);
    },
    async fetchSenders({ commit }) {
      commit("SET_LOADING", true);
      const { data } = await SystemServices.fetchSenders();
      commit("SET_SENDERS", data.data.senders);
      commit("SET_LOADING", false);
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
  },
  mutations: {
    SET_SENDERS(state, senders) {
      state.senders = senders;
    },
    SET_PARTNER(state, partner) {
      state.partner = partner;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
};
