import Axios from "@/config/axios";

export default {
  async fetchSenders() {
    return Axios.get("/system/params/senders");
  },
  async fetchPartner() {
    return Axios.get("/system/params/partner");
  },
};
