import { createStore } from "vuex";

import layout from "./modules/layout";
import notification from "./modules/notification";
import directories from "./modules/directories";
import persons from "./modules/persons";
import documents from "./modules/documents";
import smtp from "./modules/smtp";
import events from "./modules/events";
import campaigns from "./modules/campaigns";
import system from "./modules/system";
import admins from "./modules/admins";

const store = createStore({
  state() {
    return {
      loading: false,
      transitionName: "scale-slide-left",
      transitionMode: "in-out",
    };
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    transitionName(state) {
      return state.transitionName;
    },
    transitionMode(state) {
      return state.transitionMode;
    },
  },
  actions: {
    setGlobalLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
    setTransitionName({ commit }, transitionName) {
      commit("SET_TRANSITION_NAME", transitionName);
    },
    setTransitionMode({ commit }, transitionMode) {
      commit("SET_TRANSITION_MODE", transitionMode);
    },
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_TRANSITION_NAME(state, transitionName) {
      state.transitionName = transitionName;
    },
    SET_TRANSITION_MODE(state, transitionMode) {
      state.transitionMode = transitionMode;
    },
  },

  modules: {
    layout,
    notification,
    directories,
    persons,
    documents,
    smtp,
    events,
    campaigns,
    system,
    admins,
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
