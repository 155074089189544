import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import store from "./state/store";

import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import Maska from "maska";

import VueFeather from "vue-feather";

import "@/assets/scss/config/galaxy/app.scss";
import "@vueform/slider/themes/default.css";

import { ASSETS_BASE_URL } from "./helpers/constants";

import Swal from "sweetalert2";

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

function saError(title, message) {
  Notification.requestPermission().then((result) => {
    if (result === "denied") {
      console.log("Permission wasn't granted. Allow a retry.");
      return;
    }
    if (result === "default") {
      console.log("The permission request was dismissed.");
      return;
    }
    console.log("Notification permission granted.");
    if (!document.hasFocus()) {
      new Notification(title, {
        body: message,
        icon: "",
      });
    }
  });

  // if window is not focused, show notification

  Swal.fire({
    title: title || "Une erreur est survenue",
    text: message || "Veuillez réessayer",
    icon: "error",
    confirmButtonClass: "btn btn-primary w-xs mt-2",
    buttonsStyling: false,
    showCloseButton: true,
  });
}

function saSuccess(title, message) {
  Swal.fire({
    title: title,
    text: message,
    icon: "success",
    confirmButtonClass: "btn btn-primary w-xs mt-2",
    buttonsStyling: false,
    showCloseButton: true,
  });
}

function saInfo(title, message) {
  Swal.fire({
    title: title,
    text: message,
    icon: "info",
    confirmButtonClass: "btn btn-primary w-xs mt-2",
    buttonsStyling: false,
    showCloseButton: true,
  });
}

function saStartLoading(title) {
  // Swal loading animation and indicator
  Swal.fire({
    title: title || "Chargement...",
    // Bootstrap  spinner
    html: `<div class="spinner-border text-primary" role="status"></div>`,
    allowOutsideClick: false,
    showConfirmButton: false,
  });
}

function saStopLoading() {
  Swal.close();
}

const app = createApp(App);

app.config.globalProperties.$saError = saError;
app.config.globalProperties.$saSuccess = saSuccess;
app.config.globalProperties.$saInfo = saInfo;
app.config.globalProperties.$startLoading = saStartLoading;
app.config.globalProperties.$stopLoading = saStopLoading;

app.config.globalProperties.$openQrCode = (matricule) => {
  window.open(ASSETS_BASE_URL + "/qrcode/" + matricule);
};

app
  .use(store)
  .use(router)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(vClickOutside)
  .mount("#app")
  .$nextTick(() => {
    postMessage({ payload: "removeLoading" }, "*");
  });
