import axios from "@/config/axios";

export default {
  namespaced: true,
  state() {
    return {
      persons: [],
      current_person: {},
      loading: false,
    };
  },
  getters: {
    persons(state) {
      return state.persons;
    },
    currentPerson(state) {
      return state.current_person;
    },
    loading(state) {
      return state.loading;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit("SET_LOADING", true);
      const { data } = await axios.get("/persons");
      commit("SET_PERSONS", data.data);
      commit("SET_LOADING", false);
    },
    async findOne({ commit }, id) {
      commit("SET_LOADING", true);
      const { data } = await axios.get(`/persons/${id}`);
      commit("SET_CURRENT_PERSON", data.data);
      commit("SET_LOADING", false);
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
  },
  mutations: {
    SET_PERSONS(state, persons) {
      state.persons = persons;
    },
    SET_CURRENT_PERSON(state, person) {
      state.current_person = person;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
};
